// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
    width: 350px;
    border: 1px solid #eaeaea;
    margin: 180px auto;
    padding: 35px 35px 15px 35px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 25px #cac6c6;
    box-sizing: border-box;
}

.login-container .login_title {
    text-align: center;
    margin-bottom: 40px;
    color: #505458;
    font-size: 20px;
}

.login-container .el-input {
    width: 198px;
}

.login-container .login-button {
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/login/login.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,4BAA4B;IAC5B,sBAAsB;IACtB,mBAAmB;IACnB,4BAA4B;IAC5B,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".login-container {\r\n    width: 350px;\r\n    border: 1px solid #eaeaea;\r\n    margin: 180px auto;\r\n    padding: 35px 35px 15px 35px;\r\n    background-color: #fff;\r\n    border-radius: 15px;\r\n    box-shadow: 0 0 25px #cac6c6;\r\n    box-sizing: border-box;\r\n}\r\n\r\n.login-container .login_title {\r\n    text-align: center;\r\n    margin-bottom: 40px;\r\n    color: #505458;\r\n    font-size: 20px;\r\n}\r\n\r\n.login-container .el-input {\r\n    width: 198px;\r\n}\r\n\r\n.login-container .login-button {\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
