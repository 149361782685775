import * as React from 'react'

const pageTwo = () => {
    return(
        <div>
            pageTwo页面
        </div>
    )
}

export default pageTwo