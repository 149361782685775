// import * as React from 'react'
import React, { useEffect, useState, useRef } from 'react';
import { MultiMarker, TMap } from 'tlbs-map-react';

const Mall = () => {
    const mapRef = useState(null);
    return(

        <div>
        
        <iframe 
          src="/Tmap.html"  // 引入 public 文件夹中的 HTML 文件
          width="100%" 
          height="1024" 
          title=""
        />
      </div>

    )

}

export default Mall
