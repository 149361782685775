import * as React from 'react'

const pageOne = () => {
    return(
        <div>
            pageOne页面
        </div>
    )
}

export default pageOne