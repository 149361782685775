// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-box {
    display: flex;
}
.flex-box.space-between {
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/pages/user/user.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,8BAA8B;AAClC","sourcesContent":[".flex-box {\r\n    display: flex;\r\n}\r\n.flex-box.space-between {\r\n    justify-content: space-between;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
